import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
// import TopicsRoutes from "./routes/TopicsRoutes";
// import AccountsRoutes from "./routes/AccountsRoutes";

const TopicsRoutes = React.lazy(() => import("./routes/TopicsRoutes"));
const AccountsRoutes = React.lazy(() => import("./routes/AccountsRoutes"));
const DashboardRoutes = React.lazy(() => import("./routes/DashboardRoutes"));

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    <Route 
                      path="/social-dashboard"
                      component={(route) => (
                        <Suspense fallback={<div>Loading...</div>}>
                          <DashboardRoutes context={context} route={route} />
                        </Suspense>
                      )}
                    />

                    <Route
                      path="/social-media"
                      component={(route) => (
                        <Suspense fallback={<div>Loading..</div>}>
                          <TopicsRoutes context={context} route={route} />
                        </Suspense>
                      )}
                    />
                    <Route
                      path="/accounts"
                      component={(route) => (
                        <Suspense fallback={<div>Loading..</div>}>
                          <AccountsRoutes context={context} route={route} />
                        </Suspense>
                      )}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
